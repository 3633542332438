import api from '@/store/api'
import * as OpenCC from 'opencc-js'
var All = {
  methods: {
    getTTSUrl (gender, result) {
      let path = `/ai/generate/tts`
      const options = { headers: {'showLoading': false }}
      return api.post(path, {
        text: result,
        gender: gender ? gender : 'female',
        kid: false
      }, options)
      .then((res) => {
        return res.data.url
      })
      .catch(() => {
      })
    },
    get_Translate_Dialog (theme, context, result) {
      // let self = this
      // 會話翻譯

      let path = `/ai/generate/translate/dialog`

      const converter = OpenCC.Converter({ from: 'cn', to: 'tw' })
      const options = { headers: {'showLoading': false }}
      return api.post(path, {
        content: result,
        theme: theme,
        context: context
      }, options)
      .then((res) => {
        return converter(res.data.result)
      })
      .catch(() => {
      })
    },
    playAudioStandard (dynamicId) {
      let self = this

      if (self.$refs[`playBtn${dynamicId}`]) {
        let audioPlayer = self.$refs[`playBtn${dynamicId}`]

        audioPlayer.onplaying = () => {
          self.isPlaying = true
        }
        audioPlayer.onpause = () => {
          self.isPlaying = false
        }
        // 播放切換
        if (self.isPlaying) {
          audioPlayer.pause()
          audioPlayer.currentTime = 0
          self.isPlaying = false
        } else {
          audioPlayer.play()
          self.isPlaying = true
        }
      }
    },
    copy_text_selection (text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
        })
        .catch(() => {
        })
    }
  }
}

export default All
